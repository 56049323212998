export function priceFormat(text, rounded = true) {

  if(!text) return "";

  if (typeof text !== "string") {
    text = text.toString();
  }

  if (rounded) {
    text = Math.round(parseFloat(text)).toString();
  }

  return text.replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".", ",");
}

export const parseValue = (value) => parseInt(value.toString().replace(/\s+/,""));
